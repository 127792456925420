import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 12 cm de diàmetre, primer hemisfèric, després més pla però amb els costats arrodonits cap a baix i amb ondulacions irregulars. Coloració marró groguenc a marró un poc rosat. Davall el capell es troben uns tubs fins, no gaire llargs, primer de color groc pàl·lid, després groc rovellat, verd olivaci de vells i porus amples i irregulars de color groc viu que esdevenen verd blavós si es pressionen. El peu, de corbat a flexuós, ple, de color groc amb unes costelles llargues i unides. Les espores són de color marró olivaci en massa, fusiformes, d’11-15 x 4-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      